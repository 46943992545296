import React from "react";
import { useParams } from "react-router-dom";

import UpdateDocPage from "components/pages/UpdateDoc";

const UpdateDoc = () => {
  const { id } = useParams();

  return <UpdateDocPage id={id} />;
};

export default UpdateDoc;
