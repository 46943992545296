import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import MenuPopover from "components/atoms/MenuPopover";
import MenuItem from "@mui/material/MenuItem";

const MenuPrimary = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const newDocument = () => {
    navigate("/novo-documento");
  };

  const searchDocument = () => {
    navigate("/busca-documento");
  };

  const author = () => {
    navigate("/autores");
  };

  const tags = () => {
    navigate("/palavraschave");
  };

  const logout = () => {
    navigate("/admin");
  };

  return (
    <>
      <MenuPopover>
        <MenuItem
          sx={{ m: 1 }}
          onClick={newDocument}
          selected={location.pathname === "/novo-documento" ? true : false}
        >
          Cadastrar Documento
        </MenuItem>
        <MenuItem
          sx={{ m: 1 }}
          onClick={searchDocument}
          selected={location.pathname === "/busca-documento" ? true : false}
        >
          Buscar Documentos
        </MenuItem>
        <MenuItem
          sx={{ m: 1 }}
          onClick={author}
          selected={location.pathname === "/autores" ? true : false}
        >
          Autores
        </MenuItem>
        <MenuItem
          sx={{ m: 1 }}
          onClick={tags}
          selected={location.pathname === "/palavraschave" ? true : false}
        >
          Palavras-Chave
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={logout}>
          Sair do sistema
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default MenuPrimary;
