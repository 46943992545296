import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const NoAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      return navigate("/admin");
    }
  }, [navigate]);
};

export default NoAuth;
