import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import NoAuth from "api/NoAuth";
import NewToken from "api/NewToken";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuPrimary from "components/molecules/MenuPrimary";

import LogoUff from "assets/logos/logoUff.png";

const Image = ({ src }) => {
  return <img src={src} height="30" alt="logo" />;
};

const Menu = (props) => {
  if (props.isLoggedIn) {
    return (
      <>
        <NoAuth />
        <NewToken />
        <MenuPrimary />
      </>
    );
  }
};

const ButtonAppBar = ({ isLoggedIn }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Image src={LogoUff} />
            <Typography
              variant="h6"
              fontWeight="bold"
              component="div"
              sx={{ flexGrow: 1, ml: 1 }}
            >
              Repositório Tecnológico do PPGA-VR
            </Typography>
            <Box sx={{ alignItems: "right" }}>
              <Menu isLoggedIn={isLoggedIn} />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
};

ButtonAppBar.defaultProps = {
  isLoggedIn: false,
};

ButtonAppBar.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default ButtonAppBar;
