import React from "react";
import { Routes, Route } from "react-router-dom";

import ButtonAppBar from "components/organisms/ButtonAppBar";
import Login from "components/pages/Login";
import PublicSearchDoc from "components/pages/PublicSearchDoc";

import NewDoc from "components/pages/NewDoc";
import SearchDoc from "components/pages/SearchDoc";
import ControlTag from "components/pages/ControlTag";
import ControlAuthor from "components/pages/ControlAuthor";

import Doc from "routes/doc";
import UpdateDoc from "routes/updateDoc";
import Media from "routes/media";
import Error404 from "routes/error404";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<ButtonAppBar />}>
      <Route index element={<PublicSearchDoc />} />
      <Route path="/documento/:id" element={<Doc />} />
      <Route path="/admin" element={<Login />} />
    </Route>
    <Route path="/" element={<ButtonAppBar isLoggedIn />}>
      <Route path="/novo-documento" element={<NewDoc />} />
      <Route path="/modifica-documento/:id" element={<UpdateDoc />} />
      <Route path="/media/:id" element={<Media />} />
      <Route path="/busca-documento" element={<SearchDoc />} />
      <Route path="/autores" element={<ControlAuthor />} />
      <Route path="/palavraschave" element={<ControlTag />} />
      <Route path="*" element={<Error404 />} />
    </Route>
  </Routes>
);

export default AppRoutes;
