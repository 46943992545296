import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";

const Error = ({ image, title, description }) => (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {image}
      <Typography component="h1" variant="h4">
        {title}
      </Typography>
      <Typography component="h1" variant="h5">
        {description}
      </Typography>
      <Button as={Link} to="/">
        Ir para página inicial
      </Button>
    </Box>
  </Container>
);

Error.defaultProps = {
  image: undefined,
  title: "",
  description: "",
};

Error.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Error;
