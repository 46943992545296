import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9",
  },
}));

const InfoTooltip = (props) => {
  return (
    <>
      <HtmlTooltip arrow placement={props.placement} title={props.title}>
        {props.children}
      </HtmlTooltip>
    </>
  );
};

InfoTooltip.defaultProps = {
  placement: undefined,
  title: undefined,
  children: undefined,
};

InfoTooltip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default InfoTooltip;
