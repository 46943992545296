import React from "react";

import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import LogoCampusAterrado from "assets/logos/logoCampusaterrado.png";
import LogoIchs from "assets/logos/logoIchs.png";
import LogoPPGA from "assets/logos/logoPPGA.png";

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://campusaterrado.vr.uff.br/"
        target="_blank"
      >
        STI/VCX
      </Link>
      {" 2023."}
    </Typography>
  );
};

const Image = ({ src }) => {
  return <img src={src} height="50" alt="logo" />;
};

const Footer = () => {
  return (
    <Box
      sx={{
        mt: 8,
        pb: 2,
        width: "100%",
      }}
    >
      <Container component="footer" maxWidth="sm">
        <CssBaseline />
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              textAlign: "center",
            }}
          >
            <Grid item xs={12} sm={5}>
              <Image src={LogoCampusAterrado} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Image src={LogoIchs} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Image src={LogoPPGA} />
            </Grid>
          </Grid>
          <Copyright sx={{ mt: 2 }} />
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
