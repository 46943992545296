import React from "react";
import { useParams } from "react-router-dom";

import DocPage from "components/pages/Doc";

const Doc = () => {
  const { id } = useParams();

  return <DocPage id={id} />;
};

export default Doc;
