import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import basicURL from "api/definitions";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/MessageDialog";

import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

const difTime = () => {
  const dateLogin = parseISO(localStorage.getItem("dateLogin"));
  const dateNow = new Date();
  //840000; 14min
  const minutes = 840000;
  const dif = minutes - (dateNow - dateLogin);

  return dif;
};

const SetNewToken = (props) => {
  const [error, setError] = useState(null);
  const [errorBack, setErrorBack] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  const handleClose = (event) => {
    localStorage.removeItem("token");
    return navigate("/admin");
  };

  useEffect(() => {
    setError(null);
    setErrorBack(null);
    setIsLoaded(false);

    const token = localStorage.getItem("token");
    const fullURL = basicURL + "token/update";

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    fetch(fullURL, {
      method: "GET",
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          if (result.error) {
            setErrorBack("Erro ao atualizar a sua sessão.");
          } else {
            if (result.token) {
              localStorage.setItem("token", result.token);
              props.handleRefresh();
            } else {
              setErrorBack("Erro ao atualizar a sua sessão.");
            }
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [props]);

  if (props.refresh) {
    if (errorBack || error) {
      return (
        <MessageDialog
          variant={MessageDialogVariants.error}
          title="Erro"
          content={errorBack}
          onClose={handleClose}
        />
      );
    } else if (!isLoaded) {
      return (
        <Box
          sx={{
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
  }
};

const NewToken = () => {
  const [refreshToken, setRefreshToken] = useState(false);
  const [refreshTime, setRefreshTime] = useState(false);

  const RenewToken = (props) => {
    useEffect(() => {
      setTimeout(() => {
        setRefreshToken(true);
      }, difTime());
    }, [props]);
  };

  const refreshAll = () => {
    localStorage.setItem(
      "dateLogin",
      format(new Date(), "yyyy-MM-dd HH:mm:ss")
    );
    setRefreshTime(false);
  };

  const handleRefresh = (event) => {
    setRefreshTime(true);
    setRefreshToken(false);
    refreshAll();
  };

  if (refreshToken) {
    return (
      <>
        <SetNewToken
          refreshToken={refreshToken}
          handleRefresh={handleRefresh}
        />
        <RenewToken refreshTime={refreshTime} />
      </>
    );
  } else return <RenewToken refreshTime={refreshTime} />;
};

export default NewToken;
