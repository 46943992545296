import React, { useState, useEffect, useRef } from "react";
import PageContainer from "components/templates/PageContainer";

import basicURL from "api/definitions";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";
import format from "date-fns/format";

const removeStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("dateLogin");
  localStorage.removeItem("search");
};

const ReturnAuth = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setError(null);
    setIsLoaded(false);
    if (props.load) {
      if (!props.cpf) {
        props.handleAuth("cpf");
      } else if (!props.password) {
        props.handleAuth("password");
      } else {
        const fullURL = basicURL + "login";

        let request = {
          user: props.cpf,
          pass: props.password,
        };
        const body = JSON.stringify(request);

        fetch(fullURL, {
          method: "POST",
          body: body,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              if (result.error) {
                setError("Erro.");
              } else {
                if (result.auth) {
                  if (result.permit) {
                    localStorage.setItem("token", result.token);
                    localStorage.setItem(
                      "dateLogin",
                      format(new Date(), "yyyy-MM-dd HH:mm:ss")
                    );
                    props.handleAuth("auth");
                  } else {
                    setError(
                      "Este usuário não tem permissão para acessar este sistema."
                    );
                  }
                } else {
                  setError("Usuário ou senha incorretos.");
                }
              }
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }
    }
  }, [props]);

  if (props.load && error) {
    return (
      <Box
        sx={{
          mt: 4,
          color: "error.main",
        }}
      >
        Erro: {error}
      </Box>
    );
  } else if (props.load && !isLoaded) {
    return (
      <Box
        sx={{
          mt: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
};

const Fields = () => {
  const [cpf, setCPF] = useState("");
  const [password, setPassword] = useState("");
  const [errorCPF, setErrorCPF] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  removeStorage();

  const navigate = useNavigate();

  const textFieldCPF = useRef(null);
  const textFieldPassword = useRef(null);

  const handleAuth = (childData) => {
    if (childData === "auth") {
      navigate("/novo-documento");
    } else {
      setLoad(false);
      if (childData === "cpf") {
        setErrorCPF(true);
      } else if (childData === "password") {
        textFieldPassword.current.focus();
        setErrorPassword(true);
      }
    }
  };

  const handlePublicSearch = () => {
    navigate("/");
  };

  const ErrorFields = () => {
    let error = "";
    if (errorCPF) error = "CPF";
    else if (errorPassword) error = "Senha";
    if (error) {
      return (
        <Box
          sx={{
            mt: 4,
            color: "error.main",
          }}
        >
          Erro: Por favor, preencha o campo {error}.
        </Box>
      );
    }
  };

  const handleChangeCPF = (event) => {
    if (load) setLoad(false);
    if (errorCPF) setErrorCPF(false);

    setCPF(event.target.value);
  };

  const handleChangePassword = (event) => {
    if (load) setLoad(false);
    if (errorPassword) setErrorPassword(false);

    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    textFieldCPF.current.focus();
    setLoad(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };
  return (
    <>
      <Box
        sx={{
          mt: 4,
        }}
        onKeyDown={handleKeyDown}
      >
        <TextField
          inputRef={textFieldCPF}
          error={errorCPF}
          value={cpf}
          onChange={handleChangeCPF}
          fullWidth
          margin="normal"
          id="cpf"
          label="CPF"
          name="cpf"
          autoComplete="off"
          autoFocus
          required
        />
        <TextField
          inputRef={textFieldPassword}
          error={errorPassword}
          value={password}
          onChange={handleChangePassword}
          fullWidth
          margin="normal"
          id="password"
          label="Senha"
          name="password"
          autoComplete="off"
          required
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          onClick={handleClick}
          fullWidth
          variant="contained"
          sx={{ mt: 1 }}
        >
          Entrar
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button onClick={handlePublicSearch}>
          Busca Pública de Documentos
        </Button>
      </Box>
      <Box>
        <ReturnAuth
          cpf={cpf}
          password={password}
          load={load}
          handleAuth={handleAuth}
        />
        <ErrorFields />
      </Box>
    </>
  );
};

const Login = () => {
  return (
    <PageContainer
      maxWidth="xs"
      title={"Administração do RepTec"}
      icon={<LockOutlinedIcon />}
    >
      <Fields />
    </PageContainer>
  );
};

export default Login;
