import React, { useState, useEffect } from "react";
import PublicSearchContainer, {
  ButtonsSearch,
} from "components/templates/PublicSearchContainer";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import DocCard from "components/atoms/DocCard";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import isValid from "date-fns/isValid";

import basicURL from "api/definitions";
import CircularProgress from "@mui/material/CircularProgress";

import SelectDocType, { docTypes } from "components/atoms/SelectDocType";

import LogoRepTec from "assets/logos/RepTec-logo.png";

const Image = ({ src }) => {
  return <img src={src} height="100" alt="logo" />;
};

const SelectSearchItem = (props) => {
  const [searchItem, setSearchItem] = useState("Título");

  const items = ["Título", "Autor", "Resumo", "Palavra-Chave", "Todos"];

  const handleChange = (event, newValue) => {
    setSearchItem(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setSearchItem(props.dataParentToChild);
  }, [props]);

  return (
    <Autocomplete
      value={searchItem}
      onChange={handleChange}
      disablePortal
      disableClearable
      id="searchItem"
      options={items}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Buscar Documentos por"
          margin="normal"
          fullWidth
          sx={{ minWidth: 170 }}
        />
      )}
    />
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const createData = (
  pId,
  pCodigo,
  pTitle,
  pIdDocumentoTipo,
  pDataPublicacao,
  pAutores,
  pTags
) => {
  pAutores = pAutores ? pAutores.toString().replaceAll(",", ", ") : "";
  pTags = pTags ? pTags.toString().replaceAll(",", ", ") : "";
  let dataPublicacao = String(format(parseISO(pDataPublicacao), "dd/MM/yyyy"));
  const tipo = docTypes.find(
    (element) => element.id === pIdDocumentoTipo
  ).label;
  return {
    pId,
    pCodigo,
    pTitle,
    dataPublicacao,
    tipo,
    pAutores,
    pTags,
  };
};

const DataTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPage);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
    setItems([]);
    if (props.load) {
      setIsLoaded(false);

      let paramItem = "?midia=yes";
      if (props.name) {
        switch (props.searchItem) {
          case "Título":
            paramItem += "&nome=" + props.name;
            break;

          case "Autor":
            paramItem += "&autor=" + props.name;
            break;

          case "Resumo":
            paramItem += "&resumo=" + props.name;
            break;

          case "Palavra-Chave":
            paramItem += "&tag=" + props.name;
            break;

          default:
            paramItem += "&logico=OR";
            paramItem += "&nome=" + props.name;
            paramItem += "&autor=" + props.name;
            paramItem += "&resumo=" + props.name;
            paramItem += "&tag=" + props.name;
            break;
        }
      }
      if (props.type) {
        paramItem += "&idDocumentoTipo=" + props.type.id;
      }
      if (props.dateBegin) {
        if (isValid(props.dateBegin)) {
          let dataPublicacao1 = String(format(props.dateBegin, "yyyy-MM-dd"));
          paramItem += "&dataPub1=" + dataPublicacao1;
        } else {
          alert(
            'A data informada para o filtro "Data de Publicação (início)" não é válida.'
          );
        }
      }
      if (props.dateEnd) {
        if (isValid(props.dateEnd)) {
          let dataPublicacao2 = String(format(props.dateEnd, "yyyy-MM-dd"));
          paramItem += "&dataPub2=" + dataPublicacao2;
        } else {
          alert(
            'A data informada para o filtro "Data de Publicação (fim)" não é válida.'
          );
        }
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const fullURL = basicURL + "documento" + paramItem;

      fetch(fullURL, {
        method: "GET",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);

            if (result.lines > 0) {
              setItems(
                result.list.map((item) =>
                  createData(
                    item.id,
                    item.codigo,
                    item.nome,
                    item.idDocumentoTipo,
                    item.dataPublicacao,
                    item.autores,
                    item.tags
                  )
                )
              );
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (props.load) {
    if (error) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Erro: {error.message}
        </Typography>
      );
    } else if (!isLoaded) {
      return <CircularProgress />;
    } else {
      if (items.length === 0) {
        return (
          <Typography sx={{ color: "error.main" }}>
            Sua busca não encontrou nenhum resultado.
          </Typography>
        );
      } else {
        return (
          <>
            <Table sx={{ display: "inline", alignItems: "left" }}>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "Todos", value: -1 },
                    ]}
                    colSpan={3}
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={"Registros por página:"}
                    labelDisplayedRows={function defaultLabelDisplayedRows({
                      from,
                      to,
                      count,
                    }) {
                      return `${from}–${to} de ${
                        count !== -1 ? count : `mais do que ${to}`
                      }`;
                    }}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "Linhas por página:",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            {(rowsPerPage > 0
              ? items.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : items
            ).map((item) => (
              <DocCard
                key={item.pId}
                idDoc={item.pId}
                codigo={item.pCodigo}
                title={item.pTitle}
                docType={item.tipo}
                datePublic={item.dataPublicacao}
                authors={item.pAutores}
                tags={item.pTags}
              />
            ))}
          </>
        );
      }
    }
  }
};

const Fields = () => {
  const [load, setLoad] = useState(false);

  const [searchItem, setSearchItem] = useState("Título");
  const [name, setName] = useState("");

  const [afterSearch, setAfterSearch] = useState(false);

  const [checkedAdvanced, setCheckedAdvanced] = useState(false);
  const [type, setType] = useState(null);
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const handleChangeCheckAvanced = (event) => {
    handleDeleteRows();
    setCheckedAdvanced(event.target.checked);
  };

  const searchItemCallback = (childData) => {
    handleDeleteRows();
    setSearchItem(childData);
  };

  const typeCallback = (childData) => {
    handleDeleteRows();
    setType(childData);
  };

  const handleChangeName = (event) => {
    handleDeleteRows();
    setName(event.target.value);
  };

  const handleChangeDateBegin = (newValue) => {
    handleDeleteRows();

    setDateBegin(newValue);
  };

  const handleChangeDateEnd = (newValue) => {
    handleDeleteRows();

    setDateEnd(newValue);
  };

  async function handleAddRows() {
    setLoad(true);
    setAfterSearch(true);
  }

  const handleDeleteAll = () => {
    setName("");
    typeCallback(null);
    setDateBegin(null);
    setDateEnd(null);
    setCheckedAdvanced(false);
    handleDeleteRows();
  };

  const handleDeleteRows = () => {
    if (load) setLoad(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddRows();
    }
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
        }}
        onKeyDown={handleKeyDown}
      >
        <Box id="beforeSearch" sx={{ display: afterSearch ? "none" : "block" }}>
          <Box sx={{ textAlign: "center" }}>
            <Image src={LogoRepTec} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <SelectSearchItem
                dataParentToChild={searchItem}
                parentCallback={searchItemCallback}
              />
            </Grid>
            <Grid item xs={12} lg={10}>
              <TextField
                value={name}
                onChange={handleChangeName}
                fullWidth
                margin="normal"
                id="name"
                label="Texto"
                name="name"
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Box>
        <Box id="afterSearch" sx={{ display: afterSearch ? "block" : "none" }}>
          <Grid container columnSpacing={2}>
            <Grid item>
              <Image src={LogoRepTec} />
            </Grid>
            <Grid item>
              <Box sx={{ mt: 1 }}>
                <SelectSearchItem
                  dataParentToChild={searchItem}
                  parentCallback={searchItemCallback}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  value={name}
                  onChange={handleChangeName}
                  fullWidth
                  margin="normal"
                  id="name"
                  label="Texto"
                  name="name"
                  autoComplete="off"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Switch checked={checkedAdvanced} onChange={handleChangeCheckAvanced} />
        Busca avançada
        <Box
          sx={{
            display: checkedAdvanced ? true : "none",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={5}>
              <SelectDocType
                dataParentToChild={type}
                parentCallback={typeCallback}
              />
            </Grid>
            <Grid item>
              <Box
                sx={{
                  width: { xs: 315, md: 590 },
                  mb: { xs: 2, lg: 0 },
                  border: 1,
                  borderRadius: 1,
                  borderColor: "primary.main",
                  p: 2,
                }}
              >
                <DatePicker
                  disableFuture
                  value={dateBegin}
                  onChange={handleChangeDateBegin}
                  id="dateBegin"
                  label="Data de Publicação (início)"
                  name="dateBegin"
                  autoComplete="off"
                  sx={{ mr: 2, mb: { xs: 2, md: 0 } }}
                />
                <DatePicker
                  disableFuture
                  value={dateEnd}
                  onChange={handleChangeDateEnd}
                  id="dateEnd"
                  label="Data de Publicação (fim)"
                  name="dateEnd"
                  autoComplete="off"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ButtonsSearch
          id={"Search"}
          handleAddRows={handleAddRows}
          handleDeleteAll={handleDeleteAll}
        />
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <DataTable
          load={load}
          searchItem={searchItem}
          name={name}
          type={type}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
        />
      </Box>
    </>
  );
};

const PublicSearchDoc = () => {
  return (
    <PublicSearchContainer
      maxWidth="lg"
      title={"Busca por Documentos"}
      icon={<SearchIcon />}
    >
      <Fields />
    </PublicSearchContainer>
  );
};

export default PublicSearchDoc;
