import React from "react";

import Error from "components/pages/Error";

import Avatar from "@mui/material/Avatar";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";

const Image = () => {
  return (
    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
      <FindInPageOutlinedIcon />
    </Avatar>
  );
};

const error404 = () => (
  <Error
    image={<Image />}
    title="Página não encontrada"
    description="Erro 404"
  />
);

export default error404;
