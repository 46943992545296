import React, { useState, useEffect } from "react";
import PublicDocContainer from "components/templates/PublicDocContainer";

import Link from "@mui/material/Link";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";

import basicURL from "api/definitions";
import CircularProgress from "@mui/material/CircularProgress";

import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

const getDocumentType = (id) => {
  let type = "";
  switch (id) {
    case 1:
      type = "Empresa ou Organização Social (inovadora)";
      break;

    case 2:
      type = "Processo/Tecnologia e Produto/Material não patenteáveis";
      break;

    case 3:
      type = "Relatório técnico conclusivo";
      break;

    case 4:
      type = "Tecnologia social";
      break;

    case 5:
      type = "Norma ou marco regulatório";
      break;

    case 6:
      type = "Patente";
      break;

    case 7:
      type = "Produtos/Processos em sigilo";
      break;

    case 8:
      type = "Software/aplicativo";
      break;

    case 9:
      type = "Base de dados técnico-científica";
      break;

    case 10:
      type = "Curso para formação profissional";
      break;

    case 11:
      type = "Material didático";
      break;

    case 12:
      type = "Produto bibliográfico na forma de artigo técnico/tecnológico";
      break;

    default:
      type = "Outros";
      break;
  }
  return type;
};

const createData = (
  pId,
  pTitle,
  pIdDocumentoTipo,
  pDataPublicacao,
  pAutores,
  pTags,
  pResumo
) => {
  pId = String(pId);
  pAutores = pAutores ? pAutores.toString().replaceAll(",", ", ") : "";
  pTags = pTags ? pTags.toString().replaceAll(",", ", ") : "";
  let dataPublicacao = String(format(parseISO(pDataPublicacao), "dd/MM/yyyy"));
  const tipo = getDocumentType(pIdDocumentoTipo);
  return {
    pId,
    pTitle,
    dataPublicacao,
    tipo,
    pAutores,
    pTags,
    pResumo,
  };
};

const DocItems = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [medias, setMedias] = useState([]);

  const idDoc = props.id - 1000;

  useEffect(() => {
    const fullURL = basicURL + "documento/" + idDoc;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch(fullURL, {
      method: "GET",
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          if (result.exist) {
            setItems(
              createData(
                result.id,
                result.nome,
                result.idDocumentoTipo,
                result.dataPublicacao,
                result.autores,
                result.tags,
                result.resumo
              )
            );
            setMedias(result.midias);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [idDoc]);

  if (error) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Erro: {error.message}
      </Typography>
    );
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.pTitle) {
      return (
        <Box sx={{ mt: 4 }}>
          <Typography gutterBottom variant="h6" component="div">
            {items.pTitle}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <strong>Tipo de Documento:</strong> {items.tipo}
            </Grid>
            <Grid item xs={12} md={6}>
              <strong>Data de Publicação:</strong> {items.dataPublicacao}
            </Grid>
            <Grid item xs={12}>
              <strong>Autores:</strong> {items.pAutores}
            </Grid>
            <Grid item xs={12}>
              <strong>Palavras-Chave:</strong> {items.pTags}
            </Grid>
            <Grid item xs={12}>
              <Typography align="justify">
                <strong>Resumo:</strong> {items.pResumo}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <strong>Mídias:</strong>
            <ul>
              {medias.map((media) => (
                <li key={media.id}>
                  <Link
                    id={media.nome}
                    href={
                      basicURL +
                      "documento/" +
                      props.id +
                      "/midia/" +
                      media.id +
                      "/download"
                    }
                    target="_blank"
                  >
                    {media.nome}
                  </Link>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ mt: 4, mb: 2 }}>
          <Typography color="error.main">
            Não foi encontrado documento para este link.
          </Typography>
        </Box>
      );
    }
  }
};

const Doc = (props) => {
  return (
    <PublicDocContainer maxWidth="lg" title={"Documento"}>
      <DocItems {...props} />
    </PublicDocContainer>
  );
};

export default Doc;
