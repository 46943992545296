import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Footer from "components/organisms/Footer";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

import basicURL from "api/definitions";

import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import StyledTable from "components/molecules/StyledTable";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/MessageDialog";

import CircularProgress from "@mui/material/CircularProgress";

let item = "";
let fetchItem = "";

const DataTable = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const handleClickEdit = (event) => {
    const idName = event.currentTarget.id;
    const arr = String(idName).split("&");

    props.clickEditCallback(arr[0], arr[1]);
  };

  const handleClickDelete = (event) => {
    const idName = event.currentTarget.id;
    const arr = String(idName).split("&");

    props.clickDeleteCallback(arr[0], arr[1]);
  };

  useEffect(() => {
    const fullURL = basicURL + fetchItem;
    fetch(fullURL, {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.map((item) => item));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [props.refresh]);

  if (error) {
    return <Box>Erro: {error.message}</Box>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    const itemsFiltered = items.filter((item) =>
      String(item.nome)
        .toLowerCase()
        .includes(String(props.filter).toLowerCase())
    );

    return (
      <StyledTable
        id={"TableItem"}
        rows={itemsFiltered}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
      />
    );
  }
};

const CreateItem = (props) => {
  const [error, setError] = useState(null);
  const [errorBack, setErrorBack] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setError(null);
    setErrorBack(null);
    setIsLoaded(false);
    if (props.loadCreate) {
      const token = localStorage.getItem("token");

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");

      let request = {
        nome: props.createNameItem,
      };
      const body = JSON.stringify(request);

      const fullURLCreate = basicURL + fetchItem + "new";
      fetch(fullURLCreate, {
        method: "POST",
        headers: myHeaders,
        body: body,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (!result.performed) {
              result.collision
                ? setErrorBack("Esse item já existe.")
                : setErrorBack("O sistema está fora do ar.");
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (error) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={"" + error.message}
        onClose={props.handleClose}
      />
    );
  } else if (errorBack) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={errorBack}
        onClose={props.handleClose}
      />
    );
  } else if (props.loadCreate && !isLoaded) {
    return <CircularProgress />;
  } else {
    if (props.loadCreate) {
      return (
        <MessageDialog
          variant={MessageDialogVariants.success}
          title="Cadastro"
          content="Cadastrado com sucesso."
          onClose={props.handleClose}
        />
      );
    }
  }
};

const UpdateItem = (props) => {
  const [error, setError] = useState(null);
  const [errorBack, setErrorBack] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setError(null);
    setErrorBack(null);
    setIsLoaded(false);
    if (props.loadUpdate) {
      const token = localStorage.getItem("token");

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");

      let request = {
        nome: props.updateNameItem,
      };
      const body = JSON.stringify(request);

      const fullURLUpdate = basicURL + fetchItem + props.idItem + "/update";
      fetch(fullURLUpdate, {
        method: "PUT",
        headers: myHeaders,
        body: body,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (!result.performed) {
              result.collision
                ? setErrorBack("Esse item já existe.")
                : setErrorBack("O sistema está fora do ar.");
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (props.loadUpdate && error) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={"" + error.message}
        onClose={props.handleClose}
      />
    );
  } else if (props.loadUpdate && errorBack) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={errorBack}
        onClose={props.handleClose}
      />
    );
  } else if (props.loadUpdate && !isLoaded) {
    return <CircularProgress />;
  } else {
    if (props.loadUpdate) {
      return (
        <MessageDialog
          variant={MessageDialogVariants.success}
          title="Edição"
          content="Editado com sucesso."
          onClose={props.handleClose}
        />
      );
    }
  }
};

const DeleteItem = (props) => {
  const [error, setError] = useState(null);
  const [errorBack, setErrorBack] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setError(null);
    setErrorBack(null);
    setIsLoaded(false);
    if (props.loadDelete) {
      const token = localStorage.getItem("token");

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);

      const fullURLDelete = basicURL + fetchItem + props.idItem + "/delete";
      fetch(fullURLDelete, {
        method: "DELETE",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (!result.performed) {
              setErrorBack("O sistema está fora do ar.");
              //result.docsAssoc (documentos associados a este item deletado)
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (error) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={"" + error.message}
        onClose={props.handleClose}
      />
    );
  } else if (errorBack) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={errorBack}
        onClose={props.handleClose}
      />
    );
  } else if (props.loadDelete && !isLoaded) {
    return <CircularProgress />;
  } else {
    if (props.loadDelete) {
      return (
        <MessageDialog
          variant={MessageDialogVariants.success}
          title="Exclusão"
          content="Excluído do sistema com sucesso."
          onClose={props.handleClose}
        />
      );
    }
  }
};

const Fields = () => {
  const [refreshTable, setRefreshTable] = useState(false);
  const [loadCreate, setLoadCreate] = useState(false);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);

  const [filter, setFilter] = useState("");
  const [idItem, setIdItem] = useState("");
  const [nameItem, setNameItem] = useState("");
  const [createNameItem, setCreateNameItem] = useState("Novo");
  const [updateNameItem, setUpdateNameItem] = useState("");

  const [openCreateConfirmDialog, setOpenCreateConfirmDialog] = useState(false);
  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };

  const handleRefreshTable = () => {
    setFilter("");
    setRefreshTable((prev) => (prev ? false : true));
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  //Create
  const handleClickCreate = (event) => {
    setOpenCreateConfirmDialog(true);
  };
  const handleChangeCreateNameItem = (event) => {
    setCreateNameItem(event.target.value);
  };
  const handleCloseCreateDialog = (event) => {
    setOpenCreateConfirmDialog(false);
  };
  const handleConfirmCreate = (event) => {
    setOpenCreateConfirmDialog(false);
    setLoadCreate(true);
  };
  const handleCloseSuccessCreate = (event) => {
    setLoadCreate(false);
    handleRefreshTable();
  };

  //Update
  const clickEditCallback = (id, name) => {
    setIdItem(id);
    setNameItem(name);
    setUpdateNameItem(name);
    setOpenUpdateConfirmDialog(true);
  };
  const handleChangeUpdateNameItem = (event) => {
    setUpdateNameItem(event.target.value);
  };
  const handleCloseUpdateDialog = (event) => {
    setOpenUpdateConfirmDialog(false);
  };
  const handleConfirmUpdate = (event) => {
    setOpenUpdateConfirmDialog(false);
    setLoadUpdate(true);
  };
  const handleCloseSuccessUpdate = (event) => {
    setLoadUpdate(false);
    handleRefreshTable();
  };

  //Delete
  const clickDeleteCallback = (id, name) => {
    setIdItem(id);
    setNameItem(name);
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = (event) => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = (event) => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleCloseSuccessDelete = (event) => {
    setLoadDelete(false);
    handleRefreshTable();
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          mt: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              inputProps={{ maxLength: 45 }}
              value={filter}
              onChange={handleChangeFilter}
              margin="normal"
              id="filter"
              label={"Filtrar " + item}
              name="filter"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={handleClickCreate}
              variant="contained"
              sx={{ mt: 3 }}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <DataTable
          filter={filter}
          clickEditCallback={clickEditCallback}
          clickDeleteCallback={clickDeleteCallback}
          refresh={refreshTable}
        />
      </Box>
      <Box id="createBox">
        <CreateItem
          loadCreate={loadCreate}
          createNameItem={createNameItem}
          handleClose={handleCloseSuccessCreate}
        />
        <MessageDialog
          confirm
          title="Cadastro"
          open={openCreateConfirmDialog}
          onClose={handleCloseCreateDialog}
          onConfirm={handleConfirmCreate}
          content={
            <>
              Você tem certeza que gostaria de cadastrar?
              <br />
              {item + ": " + createNameItem}
            </>
          }
        >
          <TextField
            required
            inputProps={{ maxLength: 45 }}
            value={createNameItem}
            onChange={handleChangeCreateNameItem}
            onFocus={handleFocus}
            fullWidth
            margin="normal"
            id="createNameItem"
            label={item}
            name="createNameItem"
            autoComplete="off"
            autoFocus
          />
        </MessageDialog>
      </Box>
      <Box id="updateBox">
        <UpdateItem
          loadUpdate={loadUpdate}
          idItem={idItem}
          updateNameItem={updateNameItem}
          handleClose={handleCloseSuccessUpdate}
        />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.warning}
          title="Edição"
          open={openUpdateConfirmDialog}
          onClose={handleCloseUpdateDialog}
          onConfirm={handleConfirmUpdate}
          content={
            <>
              Você tem certeza que gostaria de atualizar?
              <br />
              {item + " atual: " + nameItem}
              <br />
              Mudar para: {updateNameItem}
            </>
          }
        >
          <TextField
            required
            inputProps={{ maxLength: 45 }}
            value={updateNameItem}
            onChange={handleChangeUpdateNameItem}
            onFocus={handleFocus}
            fullWidth
            margin="normal"
            id="updateNameItem"
            label={"Editar " + item}
            name="updateNameItem"
            autoComplete="off"
            autoFocus
          />
        </MessageDialog>
      </Box>
      <Box id="deleteBox">
        <DeleteItem
          loadDelete={loadDelete}
          idItem={idItem}
          handleClose={handleCloseSuccessDelete}
        />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.error}
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          content={
            <>
              Você tem certeza que gostaria de excluir?
              <br />
              {item + ": " + nameItem}
            </>
          }
        />
      </Box>
    </>
  );
};

const ControlContainer = (props) => {
  item = props.item;
  fetchItem = props.fetchItem;

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{props.icon}</Avatar>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
        </Box>
        <Fields />
      </Container>
      <Footer />
    </>
  );
};

ControlContainer.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  fetchItem: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default ControlContainer;
