import React, { useState, useEffect, useRef } from "react";
import PageContainer from "components/templates/PageContainer";

import { useNavigate } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import basicURL, { mediaUploadLimit } from "api/definitions";
import CircularProgress from "@mui/material/CircularProgress";

import HelpTooltip from "components/atoms/HelpTooltip";
import MediaTable from "components/molecules/MediaTable";

import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/MessageDialog";

const mediaLimitText = mediaUploadLimit + "MB";

const ShowDoc = (props) => {
  const title = props.title;
  const authors = props.authors;
  return (
    <Box sx={{ mt: 4 }}>
      <Typography>
        <strong>Título do documento:</strong> {title}
      </Typography>
      <Typography>
        <strong>Autores:</strong> {authors}
      </Typography>
    </Box>
  );
};

const CreateMidia = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const token = localStorage.getItem("token");
      const fullURL = basicURL + "documento/" + props.idDoc + "/midia/new";

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      //myHeaders.append("Content-Type", "application/json");

      var formdata = new FormData();
      formdata.append("arquivo", props.file);

      fetch(fullURL, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);

            if (result.error) {
              setError("sistema");
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (props.load && error) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Erro: {error === "sistema" ? "Sistema fora do ar." : error.message}
      </Typography>
    );
  } else if (props.load && !isLoaded) {
    return <CircularProgress />;
  } else {
    if (props.load) {
      props.handleClose();
    }
  }
};

const UpdateMedia = (props) => {
  const [error, setError] = useState(null);
  const [errorBack, setErrorBack] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setError(null);
    setErrorBack(null);
    setIsLoaded(false);
    if (props.loadUpdate) {
      const token = localStorage.getItem("token");

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");

      let request = {
        descricao: props.updateName,
      };
      const body = JSON.stringify(request);

      const fullURLUpdate =
        basicURL +
        "documento/" +
        props.id +
        "/midia/" +
        props.idMidia +
        "/update";
      fetch(fullURLUpdate, {
        method: "PUT",
        headers: myHeaders,
        body: body,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (!result.performed) {
              result.collision
                ? setErrorBack("Esse item já existe.")
                : setErrorBack("O sistema está fora do ar.");
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (props.loadUpdate && error) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={"" + error.message}
        onClose={props.handleClose}
      />
    );
  } else if (props.loadUpdate && errorBack) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={errorBack}
        onClose={props.handleClose}
      />
    );
  } else if (props.loadUpdate && !isLoaded) {
    return <CircularProgress />;
  } else {
    if (props.loadUpdate) {
      return (
        <MessageDialog
          variant={MessageDialogVariants.success}
          title="Edição"
          content="Descrição da Mídia editada com sucesso."
          onClose={props.handleClose}
        />
      );
    }
  }
};

const DeleteMedia = (props) => {
  const [error, setError] = useState(null);
  const [errorBack, setErrorBack] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setError(null);
    setErrorBack(null);
    setIsLoaded(false);
    if (props.loadDelete) {
      const token = localStorage.getItem("token");

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);

      const fullURLDelete =
        basicURL +
        "documento/" +
        props.id +
        "/midia/" +
        props.idMidia +
        "/delete";
      fetch(fullURLDelete, {
        method: "DELETE",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (!result.performed) {
              setErrorBack("O sistema está fora do ar.");
              //result.docsAssoc (documentos associados a este item deletado)
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (error) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={"" + error.message}
        onClose={props.handleClose}
      />
    );
  } else if (errorBack) {
    return (
      <MessageDialog
        variant={MessageDialogVariants.error}
        title="Erro"
        content={errorBack}
        onClose={props.handleClose}
      />
    );
  } else if (props.loadDelete && !isLoaded) {
    return <CircularProgress />;
  } else {
    if (props.loadDelete) {
      props.handleClose();
    }
  }
};

const OpenMidias = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (props.id) {
      const fullURL = basicURL + "documento/" + props.id;
      fetch(fullURL, {
        method: "GET",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result.midias);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (props.id) {
    if (error) {
      return <Box>Erro: {error.message}</Box>;
    } else if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return (
        <Box sx={{ mt: 1 }}>
          <MediaTable
            id={"TableMedia"}
            idDoc={props.id}
            rows={items}
            handleClickEdit={props.handleClickEdit}
            handleClickDelete={props.handleClickDelete}
          />
        </Box>
      );
    }
  }
};

const Fields = (props) => {
  const [load, setLoad] = useState(false);

  const [idDoc, setIdDoc] = useState("");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Nenhuma mídia selecionada");
  const [colorFileName, setColorFileName] = useState("");

  //const [errorName, setErrorName] = useState(false);

  const refInput = useRef(null);

  const [idItem, setIdItem] = useState("");
  const [name, setName] = useState("Novo");
  const [updateName, setUpdateName] = useState("");

  const [loadUpdate, setLoadUpdate] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);

  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  useEffect(() => {
    const items = props.items;
    setIdDoc(String(items.id));
  }, [props]);

  const navigate = useNavigate();

  const handleBackNewDoc = (event) => {
    navigate("/novo-documento");
  };

  const handleBackSearchDoc = (event) => {
    navigate("/busca-documento");
  };

  const handleClose = (event) => {
    handleDeleteAll();
  };

  //Update
  const handleClickEdit = (event) => {
    const idName = event.currentTarget.id;
    const arr = String(idName).split("&");

    setIdItem(arr[0]);
    setName(arr[1]);
    setUpdateName(name);
    setOpenUpdateConfirmDialog(true);
  };
  const handleChangeUpdateName = (event) => {
    setUpdateName(event.target.value);
  };
  const handleCloseUpdateDialog = (event) => {
    setOpenUpdateConfirmDialog(false);
  };
  const handleConfirmUpdate = (event) => {
    setOpenUpdateConfirmDialog(false);
    setLoadUpdate(true);
  };
  const handleCloseSuccessUpdate = (event) => {
    setLoadUpdate(false);
    //handleRefreshTable();
  };

  //Delete
  const handleClickDelete = (event) => {
    const idName = event.currentTarget.id;
    const arr = String(idName).split("&");

    setIdItem(arr[0]);
    setName(arr[1]);
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = (event) => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = (event) => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleCloseSuccessDelete = (event) => {
    setLoadDelete(false);
    //handleDeleteAll();
    //handleRefreshTable();
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleClickChoose = (event) => {
    refInput.current.click();
  };

  const handleChangeInput = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const fileUploaded = event.target.files[0];
      setFile(fileUploaded);

      const uploadLimit = mediaUploadLimit * 1000000;

      if (fileUploaded.type === "application/pdf") {
        if (fileUploaded.size < uploadLimit) {
          setFileName(fileUploaded.name);
          setColorFileName("primary.main");
        } else {
          setFileName(
            "Essa mídia excede o tamanho máximo de " + mediaLimitText
          );
          setColorFileName("error.main");
        }
      } else {
        setFileName("Essa mídia não está no formato PDF");
        setColorFileName("error.main");
      }
    } else {
      setFileName("Nenhuma mídia selecionada");
      setColorFileName("");
    }
  };

  const handleDeleteAll = () => {
    if (load) setLoad(false);
    setFile(null);
    setFileName("Nenhuma mídia selecionada");
    setColorFileName("");
    setName("");
    setIdItem("");
    //if (errorName) setErrorName(false);
  };

  const handleClickCreate = () => {
    if (!file) {
      alert("Por favor, escolha uma mídia.");
    } else setLoad(true);
  };

  return (
    <>
      <OpenMidias
        id={idDoc}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
      />
      <Box id="updateBox">
        <UpdateMedia
          loadUpdate={loadUpdate}
          id={props.id}
          idMidia={idItem}
          updateName={updateName}
          handleClose={handleCloseSuccessUpdate}
        />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.warning}
          title="Edição"
          open={openUpdateConfirmDialog}
          onClose={handleCloseUpdateDialog}
          onConfirm={handleConfirmUpdate}
          content={
            <>
              Você tem certeza que gostaria de atualizar essa Mídia?
              <br />
              {"Descrição atual: " + name}
              <br />
              Mudar para: {updateName}
            </>
          }
        >
          <TextField
            required
            inputProps={{ maxLength: 45 }}
            value={updateName}
            onChange={handleChangeUpdateName}
            onFocus={handleFocus}
            fullWidth
            margin="normal"
            id="updateName"
            label="Editar Mídia"
            name="updateName"
            autoComplete="off"
            autoFocus
          />
        </MessageDialog>
      </Box>
      <Box id="deleteBox">
        <DeleteMedia
          loadDelete={loadDelete}
          id={props.id}
          idMidia={idItem}
          handleClose={handleCloseSuccessDelete}
        />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.error}
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          content={
            <>
              Você tem certeza que gostaria de excluir essa mídia?
              <br />
              {name}
            </>
          }
        />
      </Box>
      <Box
        component="form"
        encType="multipart/form-data"
        sx={{
          mt: 4,
        }}
      >
        <Divider
          textAlign="left"
          sx={{
            mb: 2,
            fontWeight: 500,
          }}
        >
          Escolher e Cadastrar Mídia
          <HelpTooltip placement="right" sx={{ mt: -1 }}>
            <Typography variant="h6">Escolher mídia</Typography>
            <Typography>
              Depois de escolher uma mídia dentro do formato e tamanho
              permitidos, o botão "Cadastrar Mídia" vai aparecer.
            </Typography>
            <ul>
              <li>
                <Typography>
                  Cadastre a mídia para associá-la a esse documento.
                </Typography>
              </li>
              <li>
                <Typography>
                  Caso contrário, escolha outra mídia e cadastre.
                </Typography>
              </li>
            </ul>
            <Typography>
              <strong>Formato de mídia permitido:</strong> PDF
            </Typography>
            <Typography>
              <strong>Tamanho máximo de cada mídia permitido:</strong>{" "}
              {mediaLimitText}
            </Typography>
          </HelpTooltip>
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={3} lg={2} sx={{ display: "none" }}>
            <Button
              fullWidth
              type="submit"
              onClick={handleDeleteAll}
              variant="contained"
              sx={{
                backgroundColor: "error.main",
                "&:hover": {
                  backgroundColor: "error.dark",
                },
              }}
            >
              Limpar
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClickChoose}
              sx={{
                backgroundColor: "success.main",
                "&:hover": {
                  backgroundColor: "success.dark",
                },
              }}
            >
              Escolher mídia
              <input
                type="file"
                ref={refInput}
                onChange={handleChangeInput}
                hidden
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            sx={{ display: colorFileName === "primary.main" ? true : "none" }}
          >
            <Button fullWidth onClick={handleClickCreate} variant="contained">
              Cadastrar Mídia
            </Button>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6" color={colorFileName}>
              Mídia selecionada: {fileName}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateMidia
          idDoc={idDoc}
          file={file}
          name={name}
          load={load}
          handleClose={handleClose}
        />
      </Box>
      <Divider
        textAlign="left"
        sx={{
          mt: 4,
          fontWeight: 500,
        }}
      >
        Voltar para a página
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={3}>
          <Button
            fullWidth
            type="submit"
            onClick={handleBackNewDoc}
            variant="contained"
            sx={{
              mt: 1,
              backgroundColor: "error.main",
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
          >
            Cadastro de Documento
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Button
            fullWidth
            type="submit"
            onClick={handleBackSearchDoc}
            variant="contained"
            sx={{
              mt: 1,
              backgroundColor: "error.main",
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
          >
            Busca de Documentos
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const createData = (pId, pTitle, pAutores) => {
  pId = String(pId);
  pAutores = pAutores ? pAutores.toString().replaceAll(",", ", ") : "";
  return {
    pId,
    pTitle,
    pAutores,
  };
};

const Media = (props) => {
  const DocItems = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsCard, setItemsCard] = useState([]);

    useEffect(() => {
      const token = localStorage.getItem("token");
      const fullURL = basicURL + "documento/" + props.id;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");

      fetch(fullURL, {
        method: "GET",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (result.exist) {
              setItems(result);
              setItemsCard(createData(result.id, result.nome, result.autores));
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }, [props.id]);

    if (error) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Erro: {error.message}
        </Typography>
      );
    } else if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <ShowDoc title={itemsCard.pTitle} authors={itemsCard.pAutores} />
          <Fields items={items} />
        </>
      );
    }
  };

  return (
    <PageContainer maxWidth="lg" title={"Mídias"} icon={<AttachFileIcon />}>
      <DocItems {...props} />
    </PageContainer>
  );
};

export default Media;
