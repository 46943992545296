import React from "react";
import { useParams } from "react-router-dom";

import MediaPage from "components/pages/Media";

const Media = () => {
  const { id } = useParams();

  return <MediaPage id={id} />;
};

export default Media;
