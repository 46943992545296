import React, { useState, useEffect, forwardRef } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const docTypes = [
  { id: "1", label: "Empresa ou Organização Social (inovadora)" },
  { id: "2", label: "Processo/Tecnologia e Produto/Material não patenteáveis" },
  { id: "3", label: "Relatório técnico conclusivo" },
  { id: "4", label: "Tecnologia social" },
  { id: "5", label: "Norma ou marco regulatório" },
  { id: "6", label: "Patente" },
  { id: "7", label: "Produtos/Processos em sigilo" },
  { id: "8", label: "Software/aplicativo" },
  { id: "9", label: "Base de dados técnico-científica" },
  { id: "10", label: "Curso para formação profissional" },
  { id: "11", label: "Material didático" },
  {
    id: "12",
    label: "Produto bibliográfico na forma de artigo técnico/tecnológico",
  },
  { id: "13", label: "Outros" },
];

const SelectDocType = forwardRef((props, ref) => {
  const [type, setType] = useState(null);
  const [errorType, setErrorType] = useState(null);

  const handleChange = (event, newValue) => {
    setType(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setType(props.dataParentToChild);
    setErrorType(props.errorParentToChild);
  }, [props.dataParentToChild, props.errorParentToChild]);

  return (
    <Autocomplete
      filterSelectedOptions
      value={type}
      onChange={handleChange}
      disablePortal
      id="type"
      options={docTypes}
      isOptionEqualToValue={(option, value) =>
        value === undefined ||
        value === "" ||
        option.id === value.id ||
        option.nome === value.nome
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="normal"
          placeholder="Selecione um Tipo de Documento"
          inputRef={ref}
          error={errorType}
          label="Tipo de Documento"
        />
      )}
    />
  );
});

export default SelectDocType;
