import React, { useState, useEffect, forwardRef } from "react";

import basicURL from "api/definitions";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import HelpTooltip from "components/atoms/HelpTooltip";

import CircularProgress from "@mui/material/CircularProgress";

const SelectAuthors = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [author, setAuthor] = useState([]);
  const [errorAuthor, setErrorAuthor] = useState(null);

  const handleChange = (event, newValue) => {
    setAuthor(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setAuthor(props.dataParentToChild);
    setErrorAuthor(props.errorParentToChild);

    const fullURL = basicURL + "autor/";
    fetch(fullURL, {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.map((item) => item.nome));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error.message}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={11}>
          <Stack>
            <Autocomplete
              multiple
              freeSolo={author.length > 11 ? false : true}
              getOptionDisabled={(options) =>
                author.length > 11 ? true : false
              }
              filterSelectedOptions
              value={author}
              onChange={handleChange}
              disablePortal
              id="author"
              options={items}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  inputRef={ref}
                  error={errorAuthor}
                  label="Autores"
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={1} sx={{ mt: 3, textAlign: "center" }}>
          <HelpTooltip placement="top-end">
            <Typography variant="h6">Autores</Typography>
            <Typography>
              Comece a digitar o nome do autor. Uma lista de sugestões aparecerá
              logo abaixo.
            </Typography>
            <ul>
              <li>
                <Typography>
                  Se o autor estiver cadastrado, selecione ele na lista.
                </Typography>
              </li>
              <li>
                <Typography>
                  Caso contrário, termine de digitar o nome completo do autor e
                  pressione a tecla [Enter] para cada autor que deseja
                  cadastrar. Ou acesse a página "Autores" no menu.
                </Typography>
              </li>
            </ul>
          </HelpTooltip>
        </Grid>
      </Grid>
    );
  }
});

export default SelectAuthors;
