import React from "react";
import ControlContainer from "components/templates/ControlContainer";

import KeyIcon from "@mui/icons-material/Key";

const ControlTag = () => {
  return (
    <ControlContainer
      title="Palavras-Chave"
      item="Palavra-Chave"
      fetchItem="tag/"
      icon={<KeyIcon />}
    />
  );
};

export default ControlTag;
