import React, { useState, useEffect, useRef } from "react";
import PageContainer from "components/templates/PageContainer";

import { useNavigate } from "react-router-dom";

import basicURL from "api/definitions";

import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import parseISO from "date-fns/parseISO";
import { format } from "date-fns";

import CircularProgress from "@mui/material/CircularProgress";

import SelectDocType, { docTypes } from "components/atoms/SelectDocType";
import SelectAuthors from "components/atoms/SelectAuthors";
import SelectTags from "components/atoms/SelectTags";

import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/MessageDialog";

const Fields = (props) => {
  const [load, setLoad] = useState(false);

  const [idDoc, setIdDoc] = useState("");
  const [name, setName] = useState("");
  const [summary, setSummary] = useState("");
  const [datePublic, setDatePublic] = useState(null);
  const [type, setType] = useState(null);
  const [author, setAuthor] = useState([]);
  const [tag, setTag] = useState([]);

  const [errorName, setErrorName] = useState(false);
  const [errorSummary, setErrorSummary] = useState(false);
  const [errorDatePublic, setErrorDatePublic] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorAuthor, setErrorAuthor] = useState(false);
  const [errorTag, setErrorTag] = useState(false);

  const refName = useRef(null);
  const refSummary = useRef(null);
  const refDatePublic = useRef(null);
  const refType = useRef(null);
  const refAuthor = useRef(null);
  const refTag = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const items = props.items;
    setIdDoc(String(items.id));
    setName(String(items.nome));
    setSummary(items.resumo);
    setDatePublic(parseISO(items.dataPublicacao));
    if (items.autores) setAuthor(items.autores);
    if (items.tags) setTag(items.tags);
    if (items.idDocumentoTipo)
      setType(
        docTypes.find((element) => element.id === String(items.idDocumentoTipo))
          .label
      );
  }, [props]);

  const handleBack = (event) => {
    navigate("/busca-documento");
  };

  const handleClose = (event) => {
    handleDeleteAll();
    handleBack();
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeSummary = (event) => {
    if (load) setLoad(false);
    if (errorSummary) setErrorSummary(false);

    setSummary(event.target.value);
  };

  const handleChangeDatePublic = (newValue) => {
    if (load) setLoad(false);
    if (errorDatePublic) setErrorDatePublic(false);

    setDatePublic(newValue);
  };

  const typeCallback = (childData) => {
    if (load) setLoad(false);
    if (errorType) setErrorType(false);

    setType(childData);
  };

  const authorCallback = (childData) => {
    if (load) setLoad(false);
    if (errorAuthor) setErrorAuthor(false);

    setAuthor(childData);
  };

  const tagCallback = (childData) => {
    if (load) setLoad(false);
    if (errorTag) setErrorTag(false);

    setTag(childData);
  };

  const handleDeleteAll = () => {
    if (load) setLoad(false);
    setName("");
    setSummary("");
    setDatePublic(null);
    typeCallback(null);
    authorCallback([]);
    tagCallback([]);
    if (errorName) setErrorName(false);
    if (errorSummary) setErrorSummary(false);
    if (errorDatePublic) setErrorDatePublic(false);
    if (errorType) setErrorType(false);
    if (errorAuthor) setErrorAuthor(false);
    if (errorTag) setErrorTag(false);
  };

  const handleClick = () => {
    const dateNow = new Date();
    let futureDate = false;
    if (datePublic >= dateNow) futureDate = true;
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else if (!type) {
      refType.current.focus();
      if (!errorType) setErrorType(true);
      alert("Por favor, preencha o Tipo de Documento.");
    } else if (!datePublic) {
      refDatePublic.current.focus();
      if (!errorDatePublic) setErrorDatePublic(true);
      alert("Por favor, preencha a Data de Publicação.");
    } else if (datePublic + "" === "Invalid Date") {
      refDatePublic.current.focus();
      if (!errorDatePublic) setErrorDatePublic(true);
      alert("Data de Publicação inválida.");
    } else if (futureDate) {
      refDatePublic.current.focus();
      if (!errorDatePublic) setErrorDatePublic(true);
      alert("A Data de Publicação não pode estar no futuro.");
    } else if (author.length === 0) {
      refAuthor.current.focus();
      if (!errorAuthor) setErrorAuthor(true);
      alert("Por favor, preencha um ou mais Autores.");
    } else if (tag.length === 0) {
      refTag.current.focus();
      if (!errorTag) setErrorTag(true);
      alert("Por favor, preencha uma ou mais Palavras-Chave.");
    } else if (!summary) {
      refSummary.current.focus();
      if (!errorSummary) setErrorSummary(true);
      alert("Por favor, preencha o Resumo.");
    } else {
      setLoad(true);
    }
  };

  const ModifyDoc = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      if (props.load) {
        setIsLoaded(false);

        const token = localStorage.getItem("token");
        const fullURL = basicURL + "documento/" + props.idDoc + "/update";

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/json");

        const dateFormated = format(props.datePublic, "yyyy/MM/dd HH:mm:ss");
        let request = {
          idDocumentoTipo: props.type.id,
          nome: props.name,
          resumo: props.summary,
          dataPublicacao: dateFormated,
          tags: props.tag,
          autores: props.author,
        };
        const body = JSON.stringify(request);

        fetch(fullURL, {
          method: "PUT",
          headers: myHeaders,
          body: body,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setIsLoaded(true);

              if (!result.performed) {
                if (result.id) {
                  setError("nada");
                } else {
                  setError("sistema");
                }
              }
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }
    }, [props]);

    if (props.load && error) {
      let msg = "";
      if (error === "sistema") {
        msg = "Sistema fora do ar.";
      } else if (error === "nada") {
        msg =
          "Nada foi modificado. Modifique alguma informação ou clique em voltar.";
      }
      return (
        <Typography sx={{ color: "error.main" }}>
          Erro: {msg === "" ? error.message : msg}
        </Typography>
      );
    } else if (props.load && !isLoaded) {
      return <CircularProgress />;
    } else {
      if (props.load) {
        return (
          <MessageDialog
            variant={MessageDialogVariants.success}
            title="Modificar Documento"
            content="Documento modificado com sucesso."
            onClose={props.handleClose}
          />
        );
      }
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          mt: 4,
        }}
      >
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Título"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <SelectDocType
              dataParentToChild={type}
              parentCallback={typeCallback}
              ref={refType}
              errorParentToChild={errorType}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <DatePicker
              inputRef={refDatePublic}
              error={errorDatePublic}
              value={datePublic}
              onChange={handleChangeDatePublic}
              id="datePublic"
              label="Data de Publicação"
              name="datePublic"
              autoComplete="off"
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectAuthors
              dataParentToChild={author}
              parentCallback={authorCallback}
              ref={refAuthor}
              errorParentToChild={errorAuthor}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectTags
              dataParentToChild={tag}
              parentCallback={tagCallback}
              ref={refTag}
              errorParentToChild={errorTag}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              multiline
              inputProps={{ maxLength: 3000 }}
              inputRef={refSummary}
              error={errorSummary}
              value={summary}
              onChange={handleChangeSummary}
              id="summary"
              label="Resumo"
              name="summary"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <ModifyDoc
          idDoc={idDoc}
          name={name}
          summary={summary}
          type={type}
          datePublic={datePublic}
          tag={tag}
          author={author}
          load={load}
          handleClose={handleClose}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={5} sm={4} md={3} lg={2}>
          <Button
            fullWidth
            type="submit"
            onClick={handleBack}
            variant="contained"
            sx={{
              mt: 1,
              backgroundColor: "error.main",
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
          >
            Voltar
          </Button>
        </Grid>
        <Grid item xs={5} sm={4} md={3} lg={2}>
          <Button
            fullWidth
            type="submit"
            onClick={handleClick}
            variant="contained"
            sx={{ mt: 1 }}
          >
            Modificar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const UpdateDoc = (props) => {
  const DocItems = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
      const token = localStorage.getItem("token");
      const fullURL = basicURL + "documento/" + props.id;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");

      fetch(fullURL, {
        method: "GET",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (result.exist) {
              setItems(result);
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }, [props.id]);

    if (error) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Erro: {error.message}
        </Typography>
      );
    } else if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return <Fields items={items} />;
    }
  };

  return (
    <PageContainer
      maxWidth="lg"
      title={"Modificar Documento"}
      icon={<EditIcon />}
    >
      <DocItems {...props} />
    </PageContainer>
  );
};

export default UpdateDoc;
