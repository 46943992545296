import React from "react";
import PropTypes from "prop-types";
import Footer from "components/organisms/Footer";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export const ButtonsSearch = (props) => {
  const handleAdd = () => {
    props.handleAddRows();
    localStorage.setItem("tablePage", 0);
    localStorage.setItem("tableRowsPage", 5);
  };

  const handleDelete = () => {
    props.handleDeleteAll();
    localStorage.removeItem("tablePage");
    localStorage.removeItem("tableRowsPage");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} sm={4} md={3} lg={2}>
        <Button
          type="submit"
          onClick={handleDelete}
          fullWidth
          variant="contained"
          sx={{
            mt: 1,
            backgroundColor: "error.main",
            "&:hover": {
              backgroundColor: "error.dark",
            },
          }}
        >
          Limpar
        </Button>
      </Grid>
      <Grid item xs={5} sm={4} md={3} lg={2}>
        <Button
          type="submit"
          onClick={handleAdd}
          fullWidth
          variant="contained"
          sx={{ mt: 1 }}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};

ButtonsSearch.propTypes = {
  handleAddRows: PropTypes.func.isRequired,
  handleDeleteAll: PropTypes.func.isRequired,
};

const SearchContainer = (props) => {
  return (
    <>
      <Container component="main" maxWidth={props.maxWidth}>
        <CssBaseline />
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{props.icon}</Avatar>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
        </Box>
        {props.children}
      </Container>
      <Footer />
    </>
  );
};

SearchContainer.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default SearchContainer;
