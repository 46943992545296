import React, { useState, useEffect } from "react";

import Link from "@mui/material/Link";
import basicURL from "api/definitions";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

const MediaTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const MediaTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MediaTable = (props) => {
  const idDoc = props.idDoc;
  const rows = props.rows;
  const rowsLength = rows.length;
  const handleClickDelete = props.handleClickDelete;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setPage(0);
  }, [rows]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsLength) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPage);
    setPage(0);
  };

  if (rowsLength === 0) {
    return (
      <Typography sx={{ color: "error.main", mt: 2 }}>
        Este documento não tem nenhuma mídia cadastrada.
      </Typography>
    );
  } else {
    return (
      <>
        <TableContainer>
          <Table aria-label="table pagination">
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "Todos", value: -1 },
                  ]}
                  colSpan={3}
                  count={rowsLength}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={"Registros por página:"}
                  labelDisplayedRows={function defaultLabelDisplayedRows({
                    from,
                    to,
                    count,
                  }) {
                    return `${from}–${to} de ${
                      count !== -1 ? count : `mais do que ${to}`
                    }`;
                  }}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Linhas por página:",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table aria-label="data table">
            <TableHead>
              <MediaTableRow>
                <MediaTableCell>Nome</MediaTableCell>
                <MediaTableCell sx={{ width: 10 }}>Download</MediaTableCell>
                <MediaTableCell sx={{ width: 10 }}>Excluir</MediaTableCell>
              </MediaTableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <MediaTableRow
                  key={row.id}
                  sx={{
                    bgcolor: "",
                    "&:hover": {
                      backgroundColor: "#90caf9",
                    },
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <MediaTableCell>{row.nome}</MediaTableCell>
                  <MediaTableCell sx={{ textAlign: "center" }}>
                    <Link
                      href={
                        basicURL +
                        "documento/" +
                        idDoc +
                        "/midia/" +
                        row.id +
                        "/download"
                      }
                      target="_blank"
                    >
                      <IconButton
                        sx={{
                          bgcolor: "",
                          "&:hover": {
                            backgroundColor: "success.light",
                          },
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Link>
                  </MediaTableCell>
                  <MediaTableCell>
                    <IconButton
                      onClick={handleClickDelete}
                      id={row.id + "&" + row.nome}
                      sx={{
                        bgcolor: "",
                        "&:hover": {
                          backgroundColor: "error.light",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </MediaTableCell>
                </MediaTableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
};

MediaTable.propTypes = {
  idDoc: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
};

export default MediaTable;
