import React from "react";
import ControlContainer from "components/templates/ControlContainer";

import GroupIcon from "@mui/icons-material/Group";

const ControlAuthor = () => {
  return (
    <ControlContainer
      title="Autores"
      item="Autor"
      fetchItem="autor/"
      icon={<GroupIcon />}
    />
  );
};

export default ControlAuthor;
