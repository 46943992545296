import React from "react";
import PropTypes from "prop-types";
import Footer from "components/organisms/Footer";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export const ButtonsSearch = (props) => {
  const handleAdd = () => {
    props.handleAddRows();
  };

  const handleDelete = () => {
    props.handleDeleteAll();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} sm={4} md={3} lg={2}>
        <Button
          type="submit"
          onClick={handleDelete}
          fullWidth
          variant="contained"
          sx={{
            mt: 1,
            backgroundColor: "error.main",
            "&:hover": {
              backgroundColor: "error.dark",
            },
          }}
        >
          Limpar
        </Button>
      </Grid>
      <Grid item xs={5} sm={4} md={3} lg={2}>
        <Button
          type="submit"
          onClick={handleAdd}
          fullWidth
          variant="contained"
          sx={{ mt: 1 }}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};

ButtonsSearch.propTypes = {
  handleAddRows: PropTypes.func.isRequired,
  handleDeleteAll: PropTypes.func.isRequired,
};

const PublicSearchContainer = (props) => {
  return (
    <>
      <Container component="main" maxWidth={props.maxWidth}>
        <CssBaseline />
        {props.children}
      </Container>
      <Footer />
    </>
  );
};

PublicSearchContainer.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PublicSearchContainer;
