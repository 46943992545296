import React, { useState, useEffect, useRef } from "react";
import PageContainer from "components/templates/PageContainer";

import basicURL from "api/definitions";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PostAddIcon from "@mui/icons-material/PostAdd";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import SelectDocType from "components/atoms/SelectDocType";
import SelectAuthors from "components/atoms/SelectAuthors";
import SelectTags from "components/atoms/SelectTags";
import HelpTooltip from "components/atoms/HelpTooltip";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";

import CircularProgress from "@mui/material/CircularProgress";

const CreateDoc = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const token = localStorage.getItem("token");
      const fullURL = basicURL + "documento/new";

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");

      const dateFormated = format(props.datePublic, "yyyy/MM/dd HH:mm:ss");
      let request = {
        idDocumentoTipo: props.type.id,
        nome: props.name,
        resumo: props.summary,
        dataPublicacao: dateFormated,
        tags: props.tag,
        autores: props.author,
      };
      const body = JSON.stringify(request);

      fetch(fullURL, {
        method: "POST",
        headers: myHeaders,
        body: body,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);

            if (!result.performed) {
              setError("sistema");
            } else {
              navigate(`/media/${result.id}`);
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props, navigate]);

  if (props.load && error) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Erro: {error === "sistema" ? "Sistema fora do ar." : error.message}
      </Typography>
    );
  } else if (props.load && !isLoaded) {
    return <CircularProgress />;
  }
};

const Fields = () => {
  const [load, setLoad] = useState(false);

  const [name, setName] = useState("");
  const [summary, setSummary] = useState("");
  const [datePublic, setDatePublic] = useState(null);
  const [type, setType] = useState(null);
  const [author, setAuthor] = useState([]);
  const [tag, setTag] = useState([]);

  const [errorName, setErrorName] = useState(false);
  const [errorSummary, setErrorSummary] = useState(false);
  const [errorDatePublic, setErrorDatePublic] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorAuthor, setErrorAuthor] = useState(false);
  const [errorTag, setErrorTag] = useState(false);

  const refName = useRef(null);
  const refSummary = useRef(null);
  const refDatePublic = useRef(null);
  const refType = useRef(null);
  const refAuthor = useRef(null);
  const refTag = useRef(null);

  const handleClose = (event) => {
    handleDeleteAll();
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeSummary = (event) => {
    if (load) setLoad(false);
    if (errorSummary) setErrorSummary(false);

    setSummary(event.target.value);
  };

  const handleChangeDatePublic = (newValue) => {
    if (load) setLoad(false);
    if (errorDatePublic) setErrorDatePublic(false);

    setDatePublic(newValue);
  };

  const typeCallback = (childData) => {
    if (load) setLoad(false);
    if (errorType) setErrorType(false);

    setType(childData);
  };

  const authorCallback = (childData) => {
    if (load) setLoad(false);
    if (errorAuthor) setErrorAuthor(false);

    setAuthor(childData);
  };

  const tagCallback = (childData) => {
    if (load) setLoad(false);
    if (errorTag) setErrorTag(false);

    setTag(childData);
  };

  const handleDeleteAll = () => {
    if (load) setLoad(false);
    setName("");
    setSummary("");
    setDatePublic(null);
    typeCallback(null);
    authorCallback([]);
    tagCallback([]);
    if (errorName) setErrorName(false);
    if (errorSummary) setErrorSummary(false);
    if (errorDatePublic) setErrorDatePublic(false);
    if (errorType) setErrorType(false);
    if (errorAuthor) setErrorAuthor(false);
    if (errorTag) setErrorTag(false);
  };

  const handleClick = () => {
    const dateNow = new Date();
    let futureDate = false;
    if (datePublic >= dateNow) futureDate = true;
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else if (!type) {
      refType.current.focus();
      if (!errorType) setErrorType(true);
      alert("Por favor, preencha o Tipo de Documento.");
    } else if (!datePublic) {
      refDatePublic.current.focus();
      if (!errorDatePublic) setErrorDatePublic(true);
      alert("Por favor, preencha a Data de Publicação.");
    } else if (datePublic + "" === "Invalid Date") {
      refDatePublic.current.focus();
      if (!errorDatePublic) setErrorDatePublic(true);
      alert("Data de Publicação inválida.");
    } else if (futureDate) {
      refDatePublic.current.focus();
      if (!errorDatePublic) setErrorDatePublic(true);
      alert("A Data de Publicação não pode estar no futuro.");
    } else if (author.length === 0) {
      refAuthor.current.focus();
      if (!errorAuthor) setErrorAuthor(true);
      alert("Por favor, preencha um ou mais Autores.");
    } else if (tag.length === 0) {
      refTag.current.focus();
      if (!errorTag) setErrorTag(true);
      alert("Por favor, preencha uma ou mais Palavras-Chave.");
    } else if (!summary) {
      refSummary.current.focus();
      if (!errorSummary) setErrorSummary(true);
      alert("Por favor, preencha o Resumo.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          mt: 4,
        }}
      >
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Título"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <SelectDocType
              dataParentToChild={type}
              parentCallback={typeCallback}
              ref={refType}
              errorParentToChild={errorType}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <DatePicker
              inputRef={refDatePublic}
              error={errorDatePublic}
              value={datePublic}
              onChange={handleChangeDatePublic}
              id="datePublic"
              label="Data de Publicação"
              name="datePublic"
              autoComplete="off"
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectAuthors
              dataParentToChild={author}
              parentCallback={authorCallback}
              ref={refAuthor}
              errorParentToChild={errorAuthor}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectTags
              dataParentToChild={tag}
              parentCallback={tagCallback}
              ref={refTag}
              errorParentToChild={errorTag}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              multiline
              inputProps={{ maxLength: 3000 }}
              inputRef={refSummary}
              error={errorSummary}
              value={summary}
              onChange={handleChangeSummary}
              id="summary"
              label="Resumo"
              name="summary"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateDoc
          name={name}
          summary={summary}
          type={type}
          datePublic={datePublic}
          tag={tag}
          author={author}
          load={load}
          handleClose={handleClose}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={5} sm={4} md={3} lg={2}>
          <Button
            fullWidth
            type="submit"
            onClick={handleDeleteAll}
            variant="contained"
            sx={{
              mt: 1,
              backgroundColor: "error.main",
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
          >
            Limpar
          </Button>
        </Grid>
        <Grid item xs={5} sm={4} md={3} lg={2}>
          <Button
            fullWidth
            type="submit"
            onClick={handleClick}
            variant="contained"
            sx={{ mt: 1 }}
          >
            Cadastrar
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ mt: 0.5 }}>
            <HelpTooltip placement="top-end">
              <Typography variant="h6">
                Cadastro de Documento e Mídias
              </Typography>
              <Typography>
                Se o documento for cadastrado com sucesso, o sistema ira
                encaminhar para a página "Mídias", onde possibilitará adicionar
                mídias a este documento.
              </Typography>
            </HelpTooltip>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const NewDoc = () => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Novo Documento"}
      icon={<PostAddIcon />}
    >
      <Fields />
    </PageContainer>
  );
};

export default NewDoc;
