import ThemeProvider from "styles/ThemeProvider";
import GlobalStyles from "styles/GlobalStyles";

import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <ThemeProvider>
        <GlobalStyles />
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
