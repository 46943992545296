import React from "react";
import PropTypes from "prop-types";
import Footer from "components/organisms/Footer";

import { useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export const ButtonHome = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} sm={4} md={3} lg={2}>
        <Button
          type="submit"
          onClick={handleClick}
          fullWidth
          variant="contained"
          sx={{ mt: 1 }}
        >
          Página Inicial
        </Button>
      </Grid>
    </Grid>
  );
};

const PublicDocContainer = (props) => {
  return (
    <>
      <Container component="main" maxWidth={props.maxWidth}>
        <CssBaseline />
        {props.children}
        <ButtonHome />
      </Container>
      <Footer />
    </>
  );
};

PublicDocContainer.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PublicDocContainer;
