import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Link from "@mui/material/Link";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";

import IconButton from "@mui/material/IconButton";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import InfoTooltip from "components/atoms/InfoTooltip";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import basicURL from "api/definitions";
import CircularProgress from "@mui/material/CircularProgress";

const OpenSummary = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (props.id) {
      const fullURL = basicURL + "documento/" + props.id;
      fetch(fullURL, {
        method: "GET",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result.resumo);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (props.id && error) {
    return <Box>Erro: {error.message}</Box>;
  } else if (props.id && !isLoaded) {
    return <CircularProgress />;
  } else {
    if (props.id) {
      return (
        <Typography align="justify" sx={{ pl: 4, pr: 4, pb: 2 }}>
          {items}
        </Typography>
      );
    }
  }
};

const OpenMidias = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (props.id) {
      const fullURL = basicURL + "documento/" + props.id;
      fetch(fullURL, {
        method: "GET",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result.midias);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [props]);

  if (props.id) {
    if (error) {
      return <Box>Erro: {error.message}</Box>;
    } else if (!isLoaded) {
      return <CircularProgress />;
    } else {
      if (items.length > 0) {
        return (
          <>
            <Box sx={{ mt: -2, pl: 1, pr: 4 }}>
              <ul>
                {items.map((item) => (
                  <li key={item.id}>
                    <Link
                      id={item.nome}
                      href={
                        basicURL +
                        "documento/" +
                        props.id +
                        "/midia/" +
                        item.id +
                        "/download"
                      }
                      target="_blank"
                    >
                      {item.nome}
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          </>
        );
      } else {
        return (
          <Typography color="error.main" sx={{ pl: 4, pr: 4, pb: 2 }}>
            Este documento não possui nenhuma mídia cadastrada.
          </Typography>
        );
      }
    }
  }
};

const DocCard = (props) => {
  const [idDocument, setIdDocument] = useState("");

  const handleClickSummary = (event) => {
    setIdDocument(event.currentTarget.id);
  };

  const TitleButtons = () => {
    if (props.handleClickAttachFile.length > 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={9} md={10.5}>
            <Typography gutterBottom variant="h6" component="div">
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={1} md={0.5}>
            <InfoTooltip arrow placement="top-end" title="Mídias">
              <IconButton
                onClick={props.handleClickAttachFile}
                id={props.idDoc + "&" + props.title}
                sx={{
                  bgcolor: "",
                  "&:hover": {
                    backgroundColor: "success.light",
                  },
                }}
              >
                <AttachFileIcon />
              </IconButton>
            </InfoTooltip>
          </Grid>
          <Grid item xs={1} md={0.5}>
            <InfoTooltip arrow placement="top-end" title="Editar">
              <IconButton
                onClick={props.handleClickEdit}
                id={props.idDoc + "&" + props.title}
                sx={{
                  bgcolor: "",
                  "&:hover": {
                    backgroundColor: "warning.light",
                  },
                }}
              >
                <EditIcon />
              </IconButton>
            </InfoTooltip>
          </Grid>
          <Grid item xs={1} md={0.5}>
            <InfoTooltip arrow placement="top-end" title="Excluir">
              <IconButton
                onClick={props.handleClickDelete}
                id={props.idDoc + "&" + props.title}
                sx={{
                  bgcolor: "",
                  "&:hover": {
                    backgroundColor: "error.light",
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </InfoTooltip>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Typography gutterBottom variant="h6" component="div">
          {props.title}
        </Typography>
      );
    }
  };

  return (
    <Card id={props.idDoc} sx={{ mb: 4, minWidth: 300 }}>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Link
            href={"https://reptec.vr.uff.br/documento/" + props.codigo}
            target="_blank"
          >
            {"https://reptec.vr.uff.br/documento/" + props.codigo}
          </Link>
        </Box>
        <TitleButtons />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <strong>Tipo de Documento:</strong> {props.docType}
          </Grid>
          <Grid item xs={12} md={6}>
            <strong>Data de Publicação:</strong> {props.datePublic}
          </Grid>
          <Grid item xs={12}>
            <strong>Autores:</strong> {props.authors}
          </Grid>
          <Grid item xs={12}>
            <strong>Palavras-Chave:</strong> {props.tags}
          </Grid>
        </Grid>
      </CardContent>

      <Accordion
        sx={{
          mt: 3,
        }}
      >
        <CardActionArea id={props.idDoc} onClick={handleClickSummary}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight={700}>Resumo</Typography>
          </AccordionSummary>
        </CardActionArea>
        <AccordionDetails>
          <OpenSummary id={idDocument} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <CardActionArea id={props.idDoc} onClick={handleClickSummary}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight={700}>Mídias</Typography>
          </AccordionSummary>
        </CardActionArea>
        <AccordionDetails>
          <OpenMidias id={idDocument} />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

DocCard.defaultProps = {
  idDoc: undefined,
  codigo: undefined,
  title: undefined,
  docType: undefined,
  datePublic: undefined,
  authors: undefined,
  tags: undefined,
  handleClickEdit: () => {},
  handleClickDelete: () => {},
  handleClickAttachFile: () => {},
};

DocCard.propTypes = {
  idDoc: PropTypes.string,
  codigo: PropTypes.string,
  title: PropTypes.string,
  docType: PropTypes.string,
  datePublic: PropTypes.string,
  authors: PropTypes.string,
  tags: PropTypes.string,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  handleClickAttachFile: PropTypes.func,
};

export default DocCard;
